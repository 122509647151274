<template>
  <div>Lol</div>
</template>

<script>
export default {
  name: "SubscriptionManagement",
};
</script>

<style></style>
